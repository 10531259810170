import {Injectable} from '@angular/core';
import { HotelBookingDetails } from '../../models/hotelBooking.model';

@Injectable({
  providedIn: 'root'
})
export class HotelBookingStatusService {

    public getStatus(booking: HotelBookingDetails): string {
        let status: any = {
            incomplete: 'incomplete',
            complete: 'complete'
        };
        
        if(!booking || booking === undefined) 
            return status.incomplete;

        if(!booking.days || booking.days.length == 0) 
            return status.incomplete;

        const bookedDays = booking.days.filter(d => d.selected);
        if(!bookedDays || bookedDays.length == 0)    
            return status.incomplete;

        if(booking.guestCount == 0 || !booking.roomType)
            return status.incomplete;

        return status.complete;    
    }

}

import {Component} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'aurea-awards-crm';
  public isHideMenuActive = true;
  private hideMenuPages = ['/registration', '/login'];

  constructor(private router: Router) {
    
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.isHideMenuActive = !!this.hideMenuPages.find(url => val.url.includes(url));
      }
    });
  }
}

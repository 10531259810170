import {Injectable} from '@angular/core';

@Injectable()

export class User {
  constructor(
    public email: string,
    public firstName: string,
    public lastName: string,
    public role: string,
    public companyName?: string,
    public streetNo?: string,
    public city?: string,
    public zipCode?: string,
    public biogInfo?: string,
    public avatarUrl?: string,
    public registeredDate?: string,
    public lastLoginDate?: string,
    public applied?: boolean,
    public hotelBookingId?: string,
    public presentationsId?: string[],
    public frameworkProgrammeId?: string,
    public guestSettingsId?: string,
    public presentationId?: string,
    public userId?: string,
    public status?: string,
    public guestCount?: number,
    public menuType?: string,
    public allergies?: string,
    public lastUpdated?: number
  ) {
  }
}

export class UserStatus {
  hotelBooking: string;
  presentation: string;
  all: string;
}

import {Injectable, Type} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {ActionLog} from '../models/action-log.model';

@Injectable({
  providedIn: 'root'
})
export class ActionLogService {

  private collectionName = 'action-log';

  constructor(private firestore: AngularFirestore) {

  }

  public create(action: ActionEnum, props?: any): void{
    try{  
        const uid = JSON.parse(localStorage.getItem('uid'));
        const actionDescription = ActionFactory.getDescription(action);
        let now = new Date().toISOString();
        const error = props && props.error? {
          message: props.error.message? props.error.message: null, 
          stack: props.error.stack? props.error.stack: null}: null;

        let model: ActionLog = <ActionLog>{
            uid: uid,
            email: props && props.email? props.email: null,
            error: error,
            action: actionDescription,
            loggedDate: now,
            type: ActionEnum[action]
        };

        this.firestore.collection(this.collectionName).add(model)
            .then(()=>{})
            .finally(()=>{});   
    }
    catch(e){
        console.log(`error creating action log ${e}`);
    }
  }

  private update(key: string, model: ActionLog) {
    return this.firestore.collection(this.collectionName).doc(key).update(model);
  }

  private get(key: string) {
    return this.firestore.collection(this.collectionName).doc(key);
  }

  public getUserLogs(userId: string) {
    const collectionRef = this.firestore.collection(this.collectionName).ref;
    const query = collectionRef.where('userId', '==', userId);

    return query.get();
  }  

  public getAll(limit?:number) {
    return this.firestore.collection(this.collectionName);
  }

  public ref$() {
    return this.firestore.collection(this.collectionName)
  }

}

export enum ActionEnum{
    Register = 1,
    Register_Fail,
    LoginSuccess,
    LoginFail,
    Logout,
    Event_Overview_View,
    Event_Overview_View_Timetable,
    Event_Overview_Update,
    Event_Overview_Update_Failed,
    Presentation_View,
    Presentation_Made_Changes,
    Presentation_Selected_Test_Pitch,
    Presentation_Update,
    Presentation_Update_Failed,
    FrameworkProgramme_View,
    FrameworkProgramme_Made_Changes,
    FrameworkProgramme_Update,
    FrameworkProgramme_Update_Failed,
    HotelReservation_Made_Changes,
    HotelReservation_View,
    HotelReservation_Update,
    HotelReservation_Update_Failed,
    Settings_View,
    Settings_Made_Changes,
    Settings_Update,
    Settings_Update_Failed
}

export class ActionFactory{

    public static getDescription(action: ActionEnum): string{
        let actionDescription = {
            [ActionEnum.Event_Overview_View]: 'Viewed event overview',
            [ActionEnum.Register]: 'Registered',
            [ActionEnum.Register_Fail]: 'Tried to register but failed',
            [ActionEnum.LoginSuccess]: 'Logged in successfully',
            [ActionEnum.LoginFail]: 'Failed to Logged in',
            [ActionEnum.Logout]: 'Logged out',
            [ActionEnum.Event_Overview_View]: 'Opened their event overview page',
            [ActionEnum.Event_Overview_View_Timetable]: 'Opened the event timetable',
            [ActionEnum.Event_Overview_Update]: 'Updated their event overview',
            [ActionEnum.Event_Overview_Update_Failed]: 'Tried to update their settings but failed',
            [ActionEnum.Presentation_View]: 'Opened their presentation page',
            [ActionEnum.Presentation_Made_Changes]: 'Made changed to their presentation',
            [ActionEnum.Presentation_Update]: 'Updated their presentation',
            [ActionEnum.Presentation_Update_Failed]: 'Tried to update their presentation but failed',
            [ActionEnum.Presentation_Selected_Test_Pitch]: 'Selected a new test pitch',
            [ActionEnum.FrameworkProgramme_View]: 'Opened their framework programme',
            [ActionEnum.FrameworkProgramme_Made_Changes]: 'Made changes to their framework programme',
            [ActionEnum.FrameworkProgramme_Update]: 'Updated their framework programme',
            [ActionEnum.FrameworkProgramme_Update_Failed]: 'Tried to update their framework programme but failed',
            [ActionEnum.HotelReservation_Made_Changes]: 'Made changes to their hotel reservation',
            [ActionEnum.HotelReservation_View]: 'Opened their hotel reservation',
            [ActionEnum.HotelReservation_Update]: 'Updated their hotel reservation',
            [ActionEnum.HotelReservation_Update_Failed]: 'Tried to update their hotel reservation but failed',
            [ActionEnum.Settings_View]: 'Opened their settings',
            [ActionEnum.Settings_Made_Changes]: 'Made changes to their settings',           
            [ActionEnum.Settings_Update]: 'Updated their settings',
            [ActionEnum.Settings_Update_Failed]: 'Tried to update their settings but failed'
        }

        return actionDescription[action] || null;
    }

}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ViewEncapsulation, SimpleChanges
} from '@angular/core';
import {
  TimetableEvent,
  TimetableLocation,
  TimetableSchedule,
  TimetableScope
} from 'ng2-wf-timetable';

import * as moment from 'moment';
import {TimeSlotModel} from '../../models/time-slot.model';
import {RestService} from '../../services/rest.service';

@Component({
  selector: 'app-timetable',
  templateUrl: './timetable.component.html',
  styleUrls: ['./timetable.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimetableComponent implements OnInit, OnChanges {
  @Input() timeTableEvents: TimeSlotModel[];
  @Input() isUpdateTimeTableActive: boolean;


  @Input() newTimeSlot: TimeSlotModel;
  @Input() deleteTimeSlot: { event: TimeSlotModel, id: string };


  @Input() isAdmin: boolean;
  @Output() editEvent: EventEmitter<any> = new EventEmitter();

  scope: TimetableScope;
  schedules: Array<TimetableSchedule>;
  timezonOffset: number;
  cellWidth = 30; // px

  constructor(private restService: RestService) {
  }

  initTableData() {
    // clear existed data
    this.schedules = [];

    const locations = [];
    let timeArray = [];
    this.timeTableEvents.forEach(event => {
      // set all existed dates of events to timeArray
      timeArray = [...timeArray, event.start, event.end];
      // generate timeslot for Timtable.js
      const eventInfo = {
        start: event.start,
        end: event.end,
        title: event.description
      };
      // set new event to location ( if it exists) or create new location with new event
      const locationIndex = locations.findIndex(location => location.title === event.location);
      if (locationIndex !== -1) {
        locations[locationIndex].events.push(eventInfo);
      } else {
        locations.push({title: event.location, events: [eventInfo]});
      }
    });

    // get start and end hour for timetable
    timeArray = timeArray
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
    const startHour = moment(timeArray[0]).utc().hour();
    // const endHour = moment(timeArray[timeArray.length - 1]).utc().hour() + 1;
    const timetableStartTime = new Date(new Date(timeArray[0]).setHours(startHour, 0, 0));
    // const timetableEndTime = new Date(new Date(timeArray[timeArray.length - 1]).setHours(endHour, 0, 0));
    const timetableEndTime = new Date(new Date(timeArray[timeArray.length - 1]).setHours(23, 59, 0));

    // init variables for timetable
    this.scope = new TimetableScope(timetableStartTime, timetableEndTime, this.cellWidth);
    this.schedules = this.createTimetableScheduleList(locations);
  }

  createTimetableScheduleList(locationsList: any[]): any[] {
    const timetableLocationList = [];
    locationsList.forEach((val, i) => {
      timetableLocationList.push(
        new TimetableSchedule(
          new TimetableLocation(`${i}`, val.title), this.getEvents(val.events, i)
        )
      );
    });
    return timetableLocationList;
  }

  getEvents(list: any[], locationInd: number) {
    return list.map((data, ind) => {
      const startHour = moment(data.start).utc().hour();
      const endHour = moment(data.end).utc().hour();
      const startMinutes = moment(data.start).utc().minutes();
      const endMinutes = moment(data.end).utc().minutes();
      return new TimetableEvent(`${locationInd}${ind + 1}`,
        new Date(new Date(data.start).setHours(startHour, startMinutes, 0)),
        new Date(new Date(data.end).setHours(endHour, endMinutes, 0)),
        data.title,
        '',
        '#BF9755');
    });
  }

  onEventPressed(event: any) {
    this.editEvent.emit(event);
  }

  ngOnInit() {
    this.timezonOffset = moment().utcOffset() / 60;
    this.initTableData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['isUpdateTimeTableActive']) {
      this.initTableData();
    }
  }
}

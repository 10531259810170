import {Component, OnInit, Input} from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {
  @Input() longitude: string;
  @Input() latitude: string;

  public mapType: string;
  public zoomValue: number;

  constructor() {
  }

  ngOnInit() {
    this.mapType = 'hybrid';
    this.zoomValue = 15;
  }

}

import {Injectable} from '@angular/core';

import {AngularFirestore} from '@angular/fire/firestore';

import {FrameworkProgramme} from '../models/frameworkProgramme.model';
import {identifierModuleUrl} from '@angular/compiler';

@Injectable({
  providedIn: 'root'
})
export class FrameProgrammeService {

  private collectionName = 'framework-programme';

  constructor(private firestore: AngularFirestore) {

  }

  public create(model: FrameworkProgramme) {
    return this.firestore.collection(this.collectionName).add(model);
  }

  public update(key: string, model: FrameworkProgramme) {
    return this.firestore.collection(this.collectionName).doc(key).update(model);
  }

  public get(key: string) {
    return this.firestore.collection(this.collectionName).doc(key);
  }

  public getFinalistFrameProgramme(userId: string) {
    const collectionRef = this.firestore.collection(this.collectionName).ref;
    const query = collectionRef.where('userId', '==', userId);

    return query.get();
  }

  public getAll() {
    return this.firestore.collection(this.collectionName);
  }

  /*
  public save(key: string, model: FrameworkProgramme, n: number){
      return key && key.length > 0?
        this.update(key, model): this.create(model);
  }*/

}

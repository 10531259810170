import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  NgZone,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import {MapsAPILoader} from '@agm/core';
import {PlaceConfig} from '../../models/config.model';


@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss']
})
export class AddressInputComponent implements OnInit, OnChanges {
  @Input() title: string;
  @Input() existedAddress: string;
  @Input() isClearAddressActive: boolean;
  @Output() setAddress: EventEmitter<PlaceConfig> = new EventEmitter();
  @ViewChild('search', {static: false}) public searchElement: ElementRef;

  public place: PlaceConfig;

  private geoCoder;

  constructor(private mapsAPILoader: MapsAPILoader,
              private ngZone: NgZone) {
  }

  onAddressChanges() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;

      const autocomplete = new google.maps.places.Autocomplete(this.searchElement.nativeElement, {
        types: ['address']
      });
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          const latitude: number = place.geometry.location.lat() || 0;
          const longitude = place.geometry.location.lng() || 0;
          const address = place.formatted_address || '';
          if (latitude !== 0 && longitude !== 0 && address.length !== 0) {
            this.setAddress.emit({address, latitude, longitude});
          }
        });
      });
    });
  }

  ngOnInit() {
    this.place = {} as PlaceConfig;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!this.existedAddress) {
      this.place.address = this.existedAddress;
    }
    if (changes['isClearAddressActive'] && changes['isClearAddressActive'].currentValue) {
      this.place = {} as PlaceConfig;
    }
  }

}

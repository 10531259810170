import {Component, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
//import { BsModalRef } from 'ngx-bootstrap/modal';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {

  // public onClose: Subject<boolean> = new Subject<boolean>();
  public name: 'confirmation popup/dialogue';

  constructor(public _bsModalRef: NgbActiveModal) {
  }

  public ngOnInit(): void {
    // this.onClose = new Subject<boolean>();
  }

  public onConfirm(): void {
    // this.onClose.next(true);
    this._bsModalRef.close(true);
  }

  public onCancel(): void {
    // this.onClose.next(false);
    this._bsModalRef.close(false);
  }

}

import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent} from './components/header/header.component';
import { SideNavigationComponent} from './components/side-navigation/side-navigation.component';
import { RouterModule} from '@angular/router';
import { NavigationsList} from './components/side-navigation/navigations-list';
import { RestService} from './services/rest.service';
import { User} from './models/user.model';
import { DayNameShortPipe } from './pipes/day-name-short.pipe';
import { TimetableComponent } from './components/timetable/timetable.component';
import { NgWfTimetableModule } from 'ng2-wf-timetable';
import { PresentationPitchesComponent } from './components/presentation-pitches/presentation-pitches.component';
import { EmailModalFormComponent } from './components/email-modal-form/email-modal-form.component';
import { FormsModule } from '@angular/forms';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { MapComponent } from './components/map/map.component';
import { AgmCoreModule } from '@agm/core';
import { TimeModalFormComponent } from './components/time-modal-form/time-modal-form.component';
import { QuillModule } from 'ngx-quill';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { CustomErrorHandler } from './services/custom-error-handler';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/** Just switch imports **/
// import {environment} from '../../environments/environment';
import { environment } from '../../environments/environment.prod';

@NgModule({
  declarations: [
    HeaderComponent,
    SideNavigationComponent,
    DayNameShortPipe,
    TimetableComponent,
    PresentationPitchesComponent,
    EmailModalFormComponent,
    MapComponent,
    TimeModalFormComponent,
    AddressInputComponent,
    ConfirmationComponent
  ],
  entryComponents: [
    EmailModalFormComponent,
    TimeModalFormComponent,
    ConfirmationComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgWfTimetableModule,
    AngularFireStorageModule,
    AgmCoreModule.forRoot({
      apiKey: environment.apiKey,
      libraries: ['places']
    }),
    QuillModule.forRoot(),
    NgbModule
  ],
  providers: [
    NavigationsList,
    User,
    RestService,
    {provide: ErrorHandler, useClass: CustomErrorHandler}
  ],
  exports: [
    HeaderComponent,
    SideNavigationComponent,
    TimetableComponent,
    DayNameShortPipe,
    PresentationPitchesComponent,
    EmailModalFormComponent,
    MapComponent,
    TimeModalFormComponent,
    AddressInputComponent
  ]
})
export class SharedModule { }

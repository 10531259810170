import {Component, OnInit, OnChanges, Input, Output, EventEmitter, SimpleChange, SimpleChanges, OnDestroy} from '@angular/core';
import * as moment from 'moment';
import {RestService} from '../../services/rest.service';
import {User} from '../../models/user.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TimeModalFormComponent} from '../time-modal-form/time-modal-form.component';
import {PresentationModel} from '../../models/presentation.model';
import {ActionEnum} from '../../services/action-logger.service';

@Component({
  selector: 'app-presentation-pitches',
  templateUrl: './presentation-pitches.component.html',
  styleUrls: ['./presentation-pitches.component.scss']
})
export class PresentationPitchesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() guestInfo: any;
  @Input() presentation: PresentationModel;
  @Input() lockDate: any;
  @Output() rehearsalAttend: EventEmitter<boolean> = new EventEmitter<boolean>();

  agreeToRehearsal: boolean = false;
  pitchDays = [];
  defaultStartHour = 9;  // set 11:00 in all timezones
  eventStartDate: string;
  userId: string;
  user: User;
  pitchesSubscribe: any;

  constructor(private restService: RestService,
              private modalService: NgbModal) {
  }

  createUserPresentation(timeSlot: any) {
    const newPresentation: any = {
      createdDate: moment.utc().format(),
      startDate: timeSlot.start,
      endDate: timeSlot.end,
      userId: this.userId,
      pitches: {
        test: {
          endDate: timeSlot.end,
          startDate: timeSlot.start
        }
      },
      presenters: [
        {
          lastName: this.user.lastName,
          firstName: this.user.firstName,
          email: this.user.email
        }
      ]
    };
    this.restService.presentationService.create(newPresentation)
      .then(res => {
        this.restService.usersService.update(this.userId, {presentationId: res.id});
      })
      .catch(err => console.log(`Create error: ${err}`));
  }

  getPitchDates(day: string, start?: number) {
    let startTime;
    if (start) {
      startTime = start;
    } else {
      const timezoneOffset = moment(day).utcOffset() / 60;
      startTime = moment(day)
        .set({
          hour: this.defaultStartHour + timezoneOffset,
          minute: 0,
          second: 0
        }).utc().format();
    }
    return {
      available: true,
      start: startTime,
      end: moment(startTime).add(1, 'hours').utc().format()
    };
  }

  initPitchesInfo(event: any) {
    this.pitchDays = [];
    if (event && event.pitchDays) {
      this.pitchDays = [...event.pitchDays];
    } else {
      this.pitchDays = [
        {
          name: moment(this.eventStartDate).format('dddd'),
          day: this.eventStartDate,
          pitches: [this.getPitchDates(this.eventStartDate)]
        }
      ];
    }
    this.pitchDays = this.pitchDays.map( day => {
      day.isAvailable = day.pitches.every( pitch => pitch.available);
      return day;
    });
  }

  initUserInfo() {
    this.userId = JSON.parse(localStorage.getItem('uid'));
    this.restService.usersService.get(this.userId)
      .toPromise()
      .then(resp => {
        this.user = resp.data() as User;
        this.user.role = this.user.role.toLowerCase();
      });
  }

  onAddDayPressed() {
    const day = moment(this.pitchDays[this.pitchDays.length - 1].day).add(1, 'days').utc().format();
    const dayName = moment(day).format('dddd');
    this.pitchDays.push(
      {
        name: dayName,
        day,
        pitches: [this.getPitchDates(day)]
      }
    );
    this.updatePitches();
  }

  onAddTimeslotPressed(dayIndex: number) {
    const day = this.pitchDays[dayIndex].day;
    const dayPitches = this.pitchDays[dayIndex].pitches;
    const startTime = dayPitches[dayPitches.length - 1].end;
    const pitch = this.getPitchDates(day, startTime);
    this.openTimeFormModal(pitch)
      .then((result) => {
        if (!!result && result !== 0) {
          dayPitches.push({
            available: true,
            start: result.start,
            end: result.end
          });
          this.updatePitches();
        }
      })
      .catch((error) => console.log(error));
  }

  openTimeFormModal(timeSlot): Promise<any> {
    const modalRef = this.modalService.open(TimeModalFormComponent, {size: 'sm'});
    modalRef.componentInstance.timeSlot = timeSlot;
    return modalRef.result;
  }

  onAttendStatusChanged() {
    this.rehearsalAttend.emit(this.agreeToRehearsal);
  }

  onDeleteDay(dayIndex: number) {
    const days = [...this.pitchDays].filter((_, i) => dayIndex !== i);
    this.restService.eventService
      .deletePitch(days)
      .then(res => console.log('res: ', res))
      .catch(err => console.log(`Delete pitch error: ${err}`));
  }

  onEditDayName(dayIndex: number) {
    this.pitchDays[dayIndex].isEditActive = true;
  }

  onSaveDayName(dayIndex: number) {
    delete this.pitchDays[dayIndex].isEditActive;
    this.updatePitches();
  }

  onDeleteTimeSlot(dayIndex: number, pitchIndex: number) {
    const pitches = JSON.parse(JSON.stringify(this.pitchDays));
    pitches[dayIndex].pitches = pitches[dayIndex].pitches.filter((_, i) => i !== pitchIndex);
    this.restService.eventService
      .deletePitch(pitches)
      .then(res => console.log('res: ', res))
      .catch(err => console.log(`Delete pitch error: ${err}`));
  }

  onEditTimeSlot(dayIndex: number, pitchIndex: number) {
    const pitch = this.pitchDays[dayIndex].pitches[pitchIndex];
    this.openTimeFormModal(pitch)
      .then((result) => {
        if (!!result && result !== 0) {
          pitch.start = result.start;
          pitch.end = result.end;
          this.updatePitches();
        }
      })
      .catch((error) => console.log(error));
  }

  onTimeSlotPressed(dayIndex: number, timeSlotIndex: number) {
    this.pitchDays = this.pitchDays.map((day, dayInd) => {
      day.pitches = day.pitches.map((pitch, pitchInd) => {
        if (dayInd === dayIndex && pitchInd === timeSlotIndex) {
          pitch.available = false;
          pitch.userId = this.userId;
        }
        if (pitch.userId && pitch.userId === this.userId && (pitchInd !== timeSlotIndex || dayInd !== dayIndex)) {
          pitch.available = true;
          pitch.userId = null;
        }
        return pitch;
      });
      return day;
    });

    this.restService.actionLogger.create(ActionEnum.Presentation_Selected_Test_Pitch);
    if (this.user.presentationId) {
      this.updateUserPresentation(this.pitchDays[dayIndex].pitches[timeSlotIndex]);
    } else {
      this.createUserPresentation(this.pitchDays[dayIndex].pitches[timeSlotIndex]);
    }
    this.updatePitches();
  }

  updatePitches() {
    return this.restService.eventService
      .updatePitches(this.pitchDays)
      .catch(err => console.log(`Update event error: ${err}`));
  }

  updateUserPresentation(timeSlot: any): void {

    const pitches = {};
    pitches['pitches.test'] = {
      endDate: timeSlot.end,
      startDate: timeSlot.start
    };

    this.restService.presentationService.update(this.user.presentationId, pitches)
      .catch(err => console.log(`Update error: ${err}`));

  }

  listenForPitchUpdates(): void {
    this.pitchesSubscribe = this.restService.eventService.pitchesDoc$()
      .valueChanges()
      .subscribe(doc => {
        this.eventStartDate = doc && doc['startDate'] ? doc['startDate'] : moment().format();
        this.initPitchesInfo(doc);
      });
    // this.restService.usersService.doc$(this.user.id).valueChanges().subscribe(doc =>{
    //   let changes = <User>doc;
    //   this.initUser(this.user.id, changes);
    // })
  }

  ngOnInit() {
    this.listenForPitchUpdates();
    this.initUserInfo();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['presentation']) {
      this.agreeToRehearsal = (changes['presentation'].currentValue as PresentationModel).agreeToRehearsal;
    }
  }

  ngOnDestroy() {
    if (this.pitchesSubscribe) {
      this.pitchesSubscribe.unsubscribe();
    }
  }

}

export const environment = {
  apiKey: 'AIzaSyAEuymsPn347bFk0mOa89MbOv6Vubl12w0', // google maps api key
  production: true,
  firebase: {
    apiKey: 'AIzaSyDyEd1isnbnKtd_kOh12axGdvlRlCzIuWQ',
    authDomain: 'aurea-crm.firebaseapp.com',
    databaseURL: 'https://aurea-crm.firebaseio.com',
    projectId: 'aurea-crm',
    storageBucket: 'aurea-crm.appspot.com',
    messagingSenderId: '924571326409',
    appId: '1:924571326409:web:0cb20a163952234c443a18',
    measurementId: 'G-LQKQ0FQSDW'
  },
  mainUrl: 'https://guest.aurea-award.com'
};

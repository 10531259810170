import {Injectable} from '@angular/core';
import { PresentationModel } from '../../models/presentation.model';

@Injectable({
  providedIn: 'root'
})
export class PresentationStatusService {

    public getStatus(presentation: PresentationModel): string {
        let status: any = {
            incomplete: 'incomplete',
            complete: 'complete'
        };
        
        if(!presentation || presentation === undefined) 
            return status.incomplete;

        if(!presentation.agreeToRehearsal || !presentation.presenters || !presentation.pitches) 
            return status.incomplete;

        if(presentation.presenters.length == 0 || !presentation.pitches.test)
            return status.incomplete;

        return status.complete;    
    }

}
import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {AngularFireStorage, AngularFireUploadTask} from '@angular/fire/storage';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable, Subscription} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';

export class RecipientModel {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'app-email-modal-form',
  templateUrl: './email-modal-form.component.html',
  styleUrls: ['./email-modal-form.component.scss']
})
export class EmailModalFormComponent implements OnInit, OnDestroy {
  @Input() recipients: string[];
  @Input() message: string;
  @Input() emailType: string;
  allowedTypes: string;
  title: string;
  email: {
    recipients: string;
    message: string;
    subject: string;
    attachedFiles?: { url: string, path: string }[];
  };
  public config = {
    toolbar: false,
  };

  public editorStyle = {
    borderTop: '1px solid #D3D8DD',
    padding: '10px',
    fontSize: '13px',
    lineHeight: '18px',
    letterSpacing: '0.01em',
    color: '#323C47'
  };
  // attachments variables
  filesToUpload: File[] = [];
  fileNameList: string[] = [];
  uploadFile: AngularFireUploadTask;
  snapshot: Subscription;
  downloadURLList: { url: string, path: string }[] = [];
  downloadURL: string;
  public invitationAttachFile = {
    url: 'https://firebasestorage.googleapis.com/v0/b/crm-dev-eafa4.appspot.com/o/attachments%2FInvitation_AUREA_Award_and_Conference.pdf?alt=media&token=1c494f5a-245b-4d6e-8fad-b0a5da7a4a91',
    path: 'attachments/Invitation_AUREA_Award_and_Conference.pdf'
  };

  constructor(public activeModal: NgbActiveModal,
              private storage: AngularFireStorage,
              private db: AngularFirestore) {
  }

  onAddAttachmentPressed(files: FileList): void {
    this.fileNameList = [];
    const newFilesArray = Object.keys(files)
      .map((prop) => isNaN(+prop) ? null : files.item(+prop))
      .filter(val => !!val);
    this.filesToUpload = [...this.filesToUpload, ...newFilesArray];
    this.fileNameList = this.filesToUpload.map(file => file.name);
  }

  onAddInvitationAttachment(e: Event) {
    this.downloadURLList = [this.invitationAttachFile];
    this.fileNameList = [this.invitationAttachFile.path];
  }

  onDeleteFilePressed(fileIndex: number) {
    if (this.filesToUpload.length !== 0) {
      this.filesToUpload.splice(fileIndex, 1);
    }
    this.fileNameList.splice(fileIndex, 1);
  }

  onClosePressed() {
    this.activeModal.close();
  }

  saveFileToStorage(files: File[]) {
    files.forEach(file => {
      // The storage path
      const path = `attachments/${Date.now()}_${file.name}`;
      // Reference to storage bucket
      const ref = this.storage.ref(path);
      // The main task
      this.uploadFile = this.storage.upload(path, file);
      // Progress monitoring
      // this.percentage = this.task.percentageChanges();

      this.snapshot = this.uploadFile.snapshotChanges().pipe(
        tap(console.log),
        // The file's download URL
        finalize(async () => {
          this.downloadURL = await ref.getDownloadURL().toPromise();
          // push file url to the list
          this.downloadURLList.push({url: this.downloadURL, path});
          // when all files were uploaded
          if (this.downloadURLList.length === this.filesToUpload.length) {
            this.setFilesUrls();
          }
        }),
      ).subscribe(_ => {
      });
    });
  }

  setFilesUrls() {
    this.email.attachedFiles = [...this.downloadURLList];
    this.filesToUpload = [];
    this.fileNameList = [];
    this.activeModal.close(this.email);
  }

  onSendPressed() {
    if (this.filesToUpload.length !== 0) {
      this.saveFileToStorage(this.filesToUpload);
    } else if (this.filesToUpload.length === 0 && this.fileNameList.length !== 0) {
      this.setFilesUrls();
    } else {
      this.activeModal.close(this.email);
    }
  }

  ngOnInit() {
    this.allowedTypes = '.png, .jpg, .jpeg, .pdf, .csv, .doc, .docx, .xml, application/msword, application/vnd.'
      + 'openxmlformats-officedocument.wordprocessingml.document';
    const recipients = this.recipients.join(', ');
    const message = this.message || '';
    this.title = this.emailType === 'email' ? 'Send E-Mail' : 'Send invitations';
    this.email = {
      recipients,
      message,
      subject: this.emailType === 'email' ? '' : 'Your Invitation to Aurea Award 2019'
    };
  }

  ngOnDestroy() {
    if (this.snapshot) {
      this.snapshot.unsubscribe();
    }
  }

}

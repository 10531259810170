import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {HotelBooking} from '../models/hotelBooking.model';

@Injectable({
  providedIn: 'root'
})
export class HotelBookingService {

  private collectionName = 'hotel-booking';

  constructor(private firestore: AngularFirestore) {

  }

  public create(model: HotelBooking) {
    return this.firestore.collection(this.collectionName).add(model);
  }

  public update(key: string, model: HotelBooking) {
    return this.firestore.collection(this.collectionName).doc(key).update(model);
  }

  public updateStatus(key: string, value: string, property: string) {
    return this.firestore.collection(this.collectionName).doc(key).update(
      {[property]: value}
    );
  }

  public get(key: string) {
    return this.firestore.collection(this.collectionName).doc(key);
  }

  public getFinalistBooking(userId: string) {
    const collectionRef = this.firestore.collection(this.collectionName).ref;
    const query = collectionRef.where('userId', '==', userId);

    return query.get();
  }

  public getAll() {
    return this.firestore.collection(this.collectionName);
  }

}

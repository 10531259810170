import { Injectable } from '@angular/core';
import { Invitation } from '../models/invitation.model';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class InviteService {
  private collectionName = 'invitations';
  invitationsRef: AngularFirestoreCollection<Invitation> = null;

  constructor( private firestore: AngularFirestore ) {
    this.invitationsRef = firestore.collection(this.collectionName);
  }

  public create(invitation: Invitation): any {
    return this.invitationsRef.add({...invitation});
  }

  public delete(key: string) {
    return this.invitationsRef.doc(key).delete();
  }

  public getInvitation( key: string): any {
    return this.invitationsRef.doc(key).get();
  }

  public getAll() {
    return this.invitationsRef;
  }

  public update(key: string, value: any): Promise<any> {
    return this.invitationsRef.doc(key).update(value);
  }
}

import {ErrorHandler, Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CustomErrorHandler implements ErrorHandler {
  private collectionName = 'error-log';
  private logs: CustomError[] = [];

  constructor(private firestore: AngularFirestore) {

  }

  handleError(error: any): void {
    const index = this.logs.findIndex(l => l.message === error.message);
    // if(index >= 0) return; //enable this to log error only once

    console.error('<error occured>', error.message);
    console.error(error);

    try {

      const uid = JSON.parse(localStorage.getItem('uid'));
      const ce = {
        uid: uid ? uid : '',
        name: error.name ? error.name : '',
        message: error.message ? error.message : '',
        fileName: error.fileName ? error.fileName : '',
        lineNumber: error.lineNumber ? error.lineNumber : '',
        columnNumber: error.columnNumber ? error.columnNumber : '',
        createdDate: moment().utc().format(),
        stack: error.stack ? error.stack : ''
      } as CustomError;

      if (index < 0) {
        console.log(`Error logging...`);
        this.create(ce)
          .then(() => {
            }
            , err => {
              console.log('unabled to create error log');
            })
          .finally(() => {
          });
        this.logs.push(ce);
      }

    } catch (e) {
      console.log(`unabled to log error ${e}`);
    }
  }

  private create(error: CustomError): Promise<any> {
    return this.firestore.collection(this.collectionName).add(error);
  }

  private getAll() {
    return this.firestore.collection(this.collectionName);
  }

}

export class CustomError {
  uid: string;
  name: string;
  message: string;
  fileName: string;
  lineNumber: string;
  columnNumber: string;
  stack: string;
  createdDate: string;
}

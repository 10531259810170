import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'dayNameShort'
})
export class DayNameShortPipe implements PipeTransform {

  transform(days: any[]): any {
    if (!days || days.length === 0) return '';
    return days.filter(val => val.selected).map(day => day.name.slice(0, 2)).join(', ');
  }

}

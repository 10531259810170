import {Injectable} from '@angular/core';
import { User, UserStatus } from '../../models/user.model';
import { PresentationModel } from '../../models/presentation.model';
import { HotelBookingDetails } from '../../models/hotelBooking.model';
import { PresentationStatusService } from './presentation.status.service';
import { HotelBookingStatusService } from './hotelBooking.status.service';

@Injectable({
  providedIn: 'root'
})
export class FinalistStatusService {

    constructor(private prStatusService:PresentationStatusService
        , private htStatusService: HotelBookingStatusService){

        }

    getStatus(user: User, presentation: PresentationModel, hotelBooking: HotelBookingDetails): UserStatus{
        let statusType: any = {
            incomplete: 'incomplete',
            complete: 'complete'
        };
        let prStatus: string = user.presentationId? this.prStatusService.getStatus(presentation) : 
            user.role.toLowerCase() === 'finalist'? statusType.incomplete: statusType.complete;
        let htStatus: string = user.hotelBookingId? this.htStatusService.getStatus(hotelBooking) : statusType.incomplete;

        let allStatus = (prStatus == statusType.incomplete || htStatus ==  statusType.incomplete) ?
            statusType.incomplete : statusType.complete;

        return <UserStatus>{
            hotelBooking: htStatus,
            presentation: prStatus,
            all: allStatus
        };
    }

}
import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {EmailModel} from '../models/email.model';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private collectionName = 'emails';
  emailsRef: AngularFirestoreCollection<EmailModel> = null;

  constructor(private firestore: AngularFirestore) {
    this.emailsRef = firestore.collection(this.collectionName);
  }

  public save(email: EmailModel): any {
    return this.emailsRef.add({...email});
  }

  public delete(key: string) {
    return this.emailsRef.doc(key).delete();
  }


  public getEmailByInvitationID(key: string) {
    const collectionRef = this.firestore.collection(this.collectionName).ref;
    const query = collectionRef.where('invitationId', '==', key);
    return query.get();
  }

  public getAll() {
    return this.emailsRef;
  }

  public update(key: string, value: any): Promise<any> {
    return this.emailsRef.doc(key).update(value);
  }
}

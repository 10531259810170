import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AdminModule} from './modules/admin/admin.module';
import {FrameProgrammeModule} from './modules/frame-programme/frame-programme.module';
import {RegistrationModule} from './modules/registration/registration.module';
import {HotelBookingModule} from './modules/hotel-booking/hotel-booking.module';
import {LoginModule} from './modules/login/login.module';
import {PresentationModule} from './modules/presentation/presentation.module';
import {SettingsModule } from './modules/settings/settings.module';
import {EventOverviewModule } from './modules/event-overview/event-overview.module';
import {TicketInfoModule} from './modules/ticket-info/ticket-info.module';

const routes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import(`./modules/admin/admin.module`).then(m => m.AdminModule)
  },
  {
    path: 'framework-programme',
    loadChildren: () => import(`./modules/frame-programme/frame-programme.module`).then(m => m.FrameProgrammeModule)
  },
  {
    path: 'registration',
    loadChildren: () => import(`./modules/registration/registration.module`).then(m => m.RegistrationModule)
  },
  {
    path: 'hotel-reservation',
    loadChildren: () => import(`./modules/hotel-booking/hotel-booking.module`).then(m => m.HotelBookingModule)
  },
  {
    path: 'settings',
    loadChildren: () => import(`./modules/settings/settings.module`).then(m => m.SettingsModule)
  },
  {
    path: 'event-overview',
    loadChildren: () => import(`./modules/event-overview/event-overview.module`).then(m => m.EventOverviewModule)
  },
  {
    path: 'presentation',
    loadChildren: () => import(`./modules/presentation/presentation.module`).then(m => m.PresentationModule)
  },
  {
    path: 'login',
    loadChildren: () => import(`./modules/login/login.module`).then(m => m.LoginModule)
  },
  {
    path: 'ticket-infos',
    loadChildren: () => import(`./modules/ticket-info/ticket-info.module`).then(m => m.TicketInfoModule)
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {

}



import { Component, OnInit, OnChanges, Input, SimpleChange, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  @Input() pageTitle: string;
  @Input() bookingProgress: string = null;

  constructor() { }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['bookingProgress']){
      console.log('booking progress changed: ' + JSON.stringify(changes['bookingProgress']));
    }
  }

}

import {Injectable} from '@angular/core';
import {AngularFirestore, Settings} from '@angular/fire/firestore';

import {FrameProgrammeService} from './frame-programme.service';
import {UserService} from './user.service';
import {InviteService} from './invite.service';
import {EmailService} from './email.service';
import {ConfigService} from './config.service';
import {AuthService} from './auth.service';
import {SettingsService} from './settings.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {HotelBookingService } from './hotelBooking.service';
import {Router} from '@angular/router';
import {EventService} from './event.service';
import { PresentationService } from './presentation.service';
import {ToastrService} from 'ngx-toastr';
import { ActionLogService } from './action-logger.service';

@Injectable({
  providedIn: 'root'
})
export class RestService {

  public hotelBookingService: HotelBookingService;
  public frameProgrammeService: FrameProgrammeService;
  public usersService: UserService;
  public invitationsService: InviteService;
  public emailsService: EmailService;
  public configService: ConfigService;
  public authService: AuthService;
  public settingsService: SettingsService;
  public eventService: EventService;
  public presentationService: PresentationService;
  public actionLogger: ActionLogService;

  constructor(private firestore: AngularFirestore,
              private afAuth: AngularFireAuth,
              public  router: Router,
              private toast: ToastrService) {

    this.hotelBookingService = new HotelBookingService(firestore);
    this.frameProgrammeService = new FrameProgrammeService(firestore);
    this.usersService = new UserService(firestore);
    this.invitationsService = new InviteService(firestore);
    this.emailsService = new EmailService(firestore);
    this.configService = new ConfigService(firestore);
    this.authService = new AuthService(afAuth, router, toast);
    this.settingsService = new SettingsService(firestore);
    this.eventService = new EventService(firestore);
    this.presentationService = new PresentationService(firestore);
    this.actionLogger = new ActionLogService(firestore);
  }
}



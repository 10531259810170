import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {User} from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private collectionName = 'users';
  public rolesList: string[] = ['Press', 'Guest', 'VIP', 'Finalist'];
  usersRef: AngularFirestoreCollection<User> = null;

  constructor(private firestore: AngularFirestore) {
    this.usersRef = firestore.collection(this.collectionName);
  }

  public create(guest: User): any {
    this.usersRef.add({...guest});
  }

  public get(key: string) {
    return this.usersRef.doc(key).get();
  }

  public doc$(key: string) {
    return this.usersRef.doc(key);
  }

  public getFinalists() {
    const collectionRef = this.firestore.collection(this.collectionName).ref;
    const query = collectionRef.where('role', '==', 'Finalist');
    return query.get();
  }

  //gets all guests including finalists
  public getGuestlists() {
    const collectionRef = this.firestore.collection(this.collectionName).ref;
    // const query = collectionRef.where('role', 'array-contains', guestRoles);
    const query = collectionRef.where('role', '>', '');
    return query.get();
  }

  public getByEmail(email: string): any {

    const collectionRef = this.firestore.collection(this.collectionName).ref;
    const query = collectionRef.where('email', '==', email);

    return query.get();
  }

  public getAll() {
    return this.usersRef;
  }

  public update(key: string, value: any): Promise<void> {
    return this.usersRef.doc(key).update(value);
  }

}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import {ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  uid: string;
  // public returnUrl = 'https://crm-dev-eafa4.web.app';
  public returnUrl = 'https://guest.aurea-award.com';

  constructor(private afAuth: AngularFireAuth,
              public  router: Router,
              private toast: ToastrService) {
    this.afAuth.authState.subscribe(user => {
      if (!user) {
        localStorage.setItem('uid', null);
      }
    }, err => console.log('AUTH err: ', err));
  }

  registerWithEmailPassword(email, pass): any {
    return this.afAuth.auth.createUserWithEmailAndPassword(email, pass);
  }

  resetPassword(email): any {
    return this.afAuth.auth.sendPasswordResetEmail(email, {url: this.returnUrl});
  }

  async loginWithEmailPassword(email: string, password: string) {
    try {
      return await  this.afAuth.auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      console.log('Login Error!' + e.message);
      this.toast.error(e.message, 'Failed');
    }
  }

  async logout() {
    await this.afAuth.auth.signOut();
    localStorage.removeItem('uid');
    this.router.navigate(['/login']);
  }

  get isLoggedIn(): boolean {
    const cachedUid = localStorage.getItem('uid');
    const uid = cachedUid && cachedUid !== 'undefined' ? JSON.parse(cachedUid) : null;
    return uid !== null;
  }

}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {NavigationsList} from './navigations-list';
import {Location} from '@angular/common';
import {RestService} from '../../services/rest.service';
import {User, UserStatus} from '../../models/user.model';
import * as moment from 'moment';
import {UserStatusFacade} from '../../facades/user-status.facade';
import {ActionEnum} from '../../services/action-logger.service';

export class SideNavUser {
  lastName: string;
  firstName: string;
  id: string;
}

@Component({
  selector: 'app-side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss']
})
export class SideNavigationComponent implements OnInit, OnDestroy {
  public activeNavigation: object;
  public activePage: string;
  public activeRole: string;
  public currentUrl: string;
  public alertMessages: string[];
  public alerts: any = {
    missingHotel: 'Missing Hotel Booking Infos',
    missingPresentation: 'Complete Presentation Infos'
  };
  public isCollapsed = false;
  public srcAvatar: string;
  public userType: string;
  public userStatus: UserStatus;
  public usersSubscribe: any;
  public isLogged: boolean;
  readonly uid: string = null;
  public seasonValue: any = null;
  public loginIcon = [
    'M200.631,236.439c-6.353,6.358-6.358,16.709,0,23.094c3.079,3.079,7.174,4.776,11.536,4.776 s8.452-1.692,11.547-4.776l59.362-59.346c1.474-1.507,2.643-3.263,3.519-5.347c0.805-1.996,1.218-4.063,1.218-6.168 c0-2.105-0.408-4.172-1.273-6.315c-0.843-1.985-2.023-3.742-3.514-5.227l-59.313-59.313c-3.079-3.084-7.18-4.781-11.547-4.781 c-4.368,0-8.469,1.702-11.536,4.781c-6.364,6.364-6.364,16.72,0,23.083l31.449,31.449H16.317C7.321,172.35,0,179.677,0,188.668 s7.321,16.317,16.317,16.317h215.762L200.631,236.439z',
    'M322.95,42.531H139.452c-30.002,0-54.402,24.4-54.402,54.391l0.033,57.698h3.263 c2.986,3.987,7.74,6.587,13.032,6.587c5.45,0,10.231-2.605,13.184-6.668l3.138-0.044V96.921c0-11.982,9.758-21.74,21.751-21.74 H322.95c11.988,0,21.751,9.758,21.751,21.74v183.503c0,11.993-9.763,21.74-21.751,21.74H139.452 c-11.993,0-21.751-9.747-21.751-21.74l-0.027-57.725H114.4c-2.986-3.981-7.74-6.57-13.027-6.57 c-5.325,0-10.106,2.627-13.086,6.663l-3.242,0.049v57.583c0,29.986,24.4,54.385,54.402,54.385H322.95 c29.996,0,54.391-24.4,54.391-54.385V96.921C377.341,66.93,352.946,42.531,322.95,42.531z'
  ];
  public user: SideNavUser = {
    lastName: 'Anonimous',
    firstName: 'Anonimous',
    id: ''
  };

  constructor(public router: Router,
              public navList: NavigationsList,
              public location: Location,
              private restService: RestService,
              private userStatusFacade: UserStatusFacade) {
    this.uid = JSON.parse(localStorage.getItem('uid'));
  }

  close() {
    if (window.screen.width < 800) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  goToLoginPage() {
    return this.router.navigate(['/login']);
  }

  onLogoutPressed() {
    this.restService.actionLogger.create(ActionEnum.Logout);
    return this.restService.authService.logout();
  }

  initUser(id: string, user: User): void {

    this.srcAvatar = user.avatarUrl ? user.avatarUrl : this.srcAvatar;
    this.userStatus = {all: 'incomplete', hotelBooking: 'incomplete', presentation: 'incomplete'} as UserStatus;
    this.activeRole = user.role ? user.role.toLowerCase() : 'guest';
    if (user && (this.activeRole === 'guest' || this.activeRole === 'finalist')) {

      user.userId = id;
      this.userStatusFacade.getStatus(user).then(status => {
        this.userStatus = status;
        // console.log('USER STATUS : ' + JSON.stringify(this.userStatus));
        this.initAlerts();
      });
    }

    this.user = {
      lastName: user.lastName,
      firstName: user.firstName,
      id
    };

    this.redirectionByRole(this.activeRole);
  }

  listenForUserChanges(): void {
    // this.firestore.collection("users").doc(this.user.id)
    this.usersSubscribe = this.restService.usersService.doc$(this.user.id).valueChanges().subscribe(doc => {
      const changes = doc as User;
      this.initUser(this.user.id, changes);
    });

  }

  getSeasonValue() {
    this.restService.eventService.getEvent()
      .toPromise()
      .then(res => res.data() ? this.seasonValue = moment(res.data().startDate).format('DD.MM.YYYY') : null);
  }


  initAlerts() {

    if (!this.userStatus) return;
    this.alertMessages = [];

    if (this.userStatus.hotelBooking === 'incomplete')
      this.alertMessages.push(this.alerts.missingHotel);
    if (this.userStatus.presentation === 'incomplete')
      this.alertMessages.push(this.alerts.missingPresentation);
  }

  redirectionByRole(role: string) {
    if (role === 'admin') {
      switch (this.currentUrl) {
        case '/event-overview':
          this.router.navigate(['/admin/overview']);
          break;
        case '/presentation':
          this.router.navigate(['/admin/presentations']);
          break;
        case '/framework-programme':
          this.router.navigate(['/admin/framework-programme']);
          break;
        case '/hotel-reservation':
          this.router.navigate(['/admin/hotel-bookings']);
          break;
        case '/settings':
          this.router.navigate(['/admin/configuration']);
          break;
        case '/activity':
            this.router.navigate(['/admin/activity']);  
          break;
      }
    } else {
      switch (this.currentUrl) {
        case '/admin/overview':
          this.router.navigate(['/event-overview']);
          break;
        case 'admin/finalists':
          this.router.navigate(['/event-overview']);
          break;
        case 'admin/guests':
          this.router.navigate(['/event-overview']);
          break;
        case 'admin/invite-guests':
          this.router.navigate(['/event-overview']);
          break;
        case '/admin/presentations':
          this.router.navigate(['/presentation']);
          break;
        case '/admin/framework-programme':
          this.router.navigate(['/framework-programme']);
          break;
        case '/admin/hotel-bookings':
          this.router.navigate(['/hotel-reservation']);
          break;
        case '/admin/configuration':
          this.router.navigate(['/settings']);
          break;
      }
    }
  }

  ngOnInit() {
    this.activePage = this.location.path() !== '' ? this.location.path().substring(1) : 'home';
    this.srcAvatar = './assets/images/user.png';
    this.alertMessages = [this.alerts.missingHotel, this.alerts.missingPresentation];
    this.isLogged = this.restService.authService.isLoggedIn;
    this.userType = '';
    this.activeNavigation = null;
    this.currentUrl = this.router.url;
    this.getSeasonValue();


    if (this.isLogged && this.uid) {
      this.restService.usersService.get(this.uid)
        .toPromise()
        .then(resp => {
          const user = resp.data() as User;
          if (user) {
            this.initUser(resp.id, user);
            this.listenForUserChanges();

            switch (this.activeRole) {
              case 'admin':
                this.activeNavigation = this.navList.adminNavigation;
                this.userType = 'Admin';
                break;
              case 'guest':
                this.activeNavigation = this.navList.guestNavigation;
                this.userType = 'Guest';                        // guest, other guest etc.
                break;
              case 'vip':
                this.activeNavigation = this.navList.guestNavigation;
                this.userType = 'VIP';                        // VIP
                break;
              case 'finalist':
                this.activeNavigation = this.navList.finalistNavigation;
                this.userType = 'Finalist';
                break;
              case 'press':
                this.activeNavigation = this.navList.guestNavigation;
                this.userType = 'Press';
                break;
              default:
                this.activeNavigation = this.navList.guestNavigation;
                this.userType = 'Guest';                        // VIP guest, other guest etc.
            }
          } else {
            this.onLogoutPressed();
          }
        })
        .catch(err => console.log('Get user error: ', err));
    } else {
      this.onLogoutPressed();
    }

  }

  ngOnDestroy() {
    if (this.usersSubscribe) {
      this.usersSubscribe.unsubscribe();
    }
  }
}


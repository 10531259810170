import {Component, OnInit, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
  selector: 'app-time-modal-form',
  templateUrl: './time-modal-form.component.html',
  styleUrls: ['./time-modal-form.component.scss']
})
export class TimeModalFormComponent implements OnInit {
  @Input() timeSlot: { start: string, end: string, available: boolean };

  public title = 'Pitch hours';
  public startTime: string;
  public endTime: string;

  constructor(public activeModal: NgbActiveModal) {
  }

  onClosePressed() {
    this.activeModal.close();
  }

  onSavePressed() {
    const timezoneOffset = moment(this.timeSlot.start).utcOffset() / 60;
    const startHours = +this.startTime.split(':')[0];
    const startMinutes = +this.startTime.split(':')[1];
    const start =  moment(this.timeSlot.start)
      .set({
        hour: startHours + timezoneOffset,
        minute: startMinutes,
        second: 0
      }).utc().format();

    const endHours = +this.endTime.split(':')[0];
    const endtMinutes = +this.endTime.split(':')[1];
    const end =  moment(this.timeSlot.start)
      .set({
        hour: endHours + timezoneOffset,
        minute: endtMinutes,
        second: 0
      }).utc().format();

    this.activeModal.close({start, end});
  }

  ngOnInit() {
    this.startTime = moment(this.timeSlot.start).utc().format('HH:mm');
    this.endTime = moment(this.timeSlot.end).utc().format('HH:mm');
  }

}

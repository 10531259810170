import {RestService} from '../services/rest.service';
import {FinalistStatusService} from '../services/validation/finalist.status.service';
import {User, UserStatus} from '../models/user.model';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserStatusFacade {
  constructor(
    private restService: RestService,
    private finalistStatusService: FinalistStatusService
  ) {
  }

  public getStatus(user: User): Promise<UserStatus> {
    return this.aggregatePropsForStatus(user);
  }

  private aggregatePropsForStatus(user: User): Promise<UserStatus> {

    return new Promise((resolve) => {
      this.gatherProperties(user, (hotel: any, frame: any, presentation: any) => {
        let finalistFull = {} as any;

        finalistFull = {
          ...finalistFull,
          ...{hotel},
          ...{frame},
          ...{presentation},
          ...{user}
        };
        const userStatus = this.finalistStatusService.getStatus(
          user,
          finalistFull.presentation,
          finalistFull.hotel
        );

        resolve(userStatus);

      });
    })

  }

  private getFrameworkProgramme(userId: string): Promise<firebase.firestore.QuerySnapshot> {
    return this.restService.frameProgrammeService.getFinalistFrameProgramme(userId);
  }

  private getHotelBooking(userId: string): Promise<firebase.firestore.QuerySnapshot> {
    return this.restService.hotelBookingService.getFinalistBooking(userId);
  }

  private getPresentation(userId: string): Promise<firebase.firestore.QuerySnapshot> {
    return this.restService.presentationService.getFinalistPresentation(userId);
  }

  gatherProperties(user: User, callBack: (hotelInfo: any, frameworkInfo: any, presentationInfo: any) => void
  ) {
    Promise.all([
      this.getFrameworkProgramme(user.userId),
      this.getHotelBooking(user.userId),
      this.getPresentation(user.userId)
    ])
      .then(
        changesArr => {
          let frame = {};
          let hotel = {};
          let presentation = {};

          if (changesArr[0])
            changesArr[0].forEach(snap => (frame = snap ? snap.data() : null));
          if (changesArr[1])
            changesArr[1].forEach(snap => (hotel = snap ? snap.data() : null));
          if (changesArr[2])
            changesArr[2].forEach(
              snap => (presentation = snap ? snap.data() : null)
            );

          callBack(hotel, frame, presentation);
        },
        err => console.log('Error : ' + err)
      );
    //.finally(() => {});
  }
}

import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {User} from '../models/user.model';
import * as firebase from 'firebase/app';
import FieldValue = firebase.firestore.FieldValue;

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private collectionName = 'events';
  private eventId = 'testEventId';    // active event ID
  eventsRef: AngularFirestoreCollection<User> = null;

  constructor(private firestore: AngularFirestore) {
    this.eventsRef = firestore.collection(this.collectionName);
  }

  public getEvent() {
    return this.eventsRef.doc(this.eventId).get();
  }

  public pitchesDoc$() {
    return this.eventsRef.doc(this.eventId);
  }

  public updatePitches(value: any[]): Promise<void> {
    return this.eventsRef.doc(this.eventId).update({pitchDays: value});
  }

  public deletePitch( daysPitches: any[] ): Promise<void> {
    return this.eventsRef.doc(this.eventId).set( { pitchDays: daysPitches}, {merge: true});
  }

  public getTimetable() {
    return this.eventsRef.doc(this.eventId).get();
  }

  public updateTimeslot(value?: object[]): Promise<any> {
    return this.eventsRef.doc(this.eventId).update({timetable: value});
  }

  public deleteTimeslot(dayIndex: number, pitchIndex: number): Promise<any> {
    const key = `${this.eventId}['pitchDays'][${dayIndex}]['pitches'][${pitchIndex}]`;
    return this.eventsRef.doc(key).delete();
  }

}


import {Injectable} from '@angular/core';

import {AngularFirestore} from '@angular/fire/firestore';

import {ConfigSettings} from '../models/config.model';
import {LocationsModel} from '../models/config.model';
import {FrameworkProgramme} from '../models/frameworkProgramme.model';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private collectionName = 'config';

  constructor(private firestore: AngularFirestore) {

  }

  public saveConfig(model: ConfigSettings): Promise<any> {
    if (!model.code)
      return this.firestore.collection(this.collectionName).add(model);

    return this.firestore.collection(this.collectionName).doc(model.code).update(model);
  }

  public initFramProgramme(model: FrameworkProgramme, key?: string): Promise<any> {
    if(!key || key.length === 0)
      return this.firestore.collection(this.collectionName).add(model);

    return this.firestore.collection(this.collectionName).doc(key).update(model);
  }

  public initSettings(model: ConfigSettings, key: string): Promise<any> {
    return this.firestore.collection(this.collectionName).doc(key).update(model);
  }

  public saveLocations(locations: LocationsModel): Promise<any> {
    return this.firestore.collection(this.collectionName).doc('locations').update(locations);
  }

  public getByCode(code: string): any {

    const collectionRef = this.firestore.collection(this.collectionName).ref;
    const query = collectionRef.where('code', '==', code);

    return query.get();
  }

}
